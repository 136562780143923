import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CreditCard from '@mui/icons-material/CreditCard';
import CreateCreditLineDialog from './CreateCreditLine';

const CreditLineActions = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<CreditCard />}
        onClick={handleOpenDialog}
      >
        Crear línea de crédito
      </Button>
      {openDialog && <CreateCreditLineDialog open={openDialog} handleClose={handleCloseDialog} />}
    </Stack>
  );
};

export default CreditLineActions;
