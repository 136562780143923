import React from 'react';
import PropTypes from 'prop-types';
import ServerSideDataGrid from '@fingo/lib/components/dataGrids/ServerDataGrid';
import { CREDIT_LINE_COLUMNS } from '../../constants/credit-line-manager';
import { CREDIT_LINES } from '../../graphql/credit-lines/queries';

const MasterEntityCreditLines = ({ masterEntityId }) => (
  <ServerSideDataGrid
    columns={CREDIT_LINE_COLUMNS}
    apolloQuery={{
      query: CREDIT_LINES,
      variables: {
        masterEntity_Id: masterEntityId,
      },
    }}
    rowHeight={80}
    height={300}
  />
);

MasterEntityCreditLines.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default MasterEntityCreditLines;
