import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { GET_COMPANY_BLACKLIST } from '@fingo/lib/graphql/customers';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import blacklistColumns from '../../constants/blacklist-columns';
import BlacklistActions from './BlacklistActions';

const Blacklist = () => {
  const [masterEntityIds, setMasterEntityIds] = useState([]);
  const columns = blacklistColumns();
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      id="blacklist-panel"
      query={GET_COMPANY_BLACKLIST}
      queryCustomVariables={{
        inBlacklist: true,
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: BlacklistActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Empresas con Potenciales Riesgo',
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        table: {
          columns,
          noRowsMessage: () => <Typography>No hay empresas en la blacklist</Typography>,
          initialOrderBy: 'name',
          checkboxSelection: true,
          rowsPerPageOptions: [10, 25, 50, 100],
          selectionModel: masterEntityIds,
          onSelectionModelChange: setMasterEntityIds,
          keepNonExistentRowsSelected: true,
        },
        actions: {
          masterEntityIds,
          setMasterEntityIds,
        },
      }}
    />
  );
};

export default Blacklist;
