import React from 'react';
import PropTypes from 'prop-types';
import BlacklistRemoveCompanyMenu from '@fingo/lib/components/inputs/BlacklistRemoveCompanyMenu';
import BlacklistAddCompanyMenu from '@fingo/lib/components/inputs/BlacklistAddCompanyMenu';

const BlacklistActions = ({ masterEntityIds, setMasterEntityIds }) => (
  <>
    <BlacklistAddCompanyMenu />
    <BlacklistRemoveCompanyMenu
      masterEntityIds={masterEntityIds}
      setMasterEntityIds={setMasterEntityIds}
    />
  </>
);

BlacklistActions.propTypes = {
  masterEntityIds: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  setMasterEntityIds: PropTypes.func.isRequired,
};

export default BlacklistActions;
