import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useMutation } from '@apollo/client';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { EDIT_RISK_BLACKLIST } from '@fingo/lib/graphql';

const RiskBlacklistTypeCell = ({ masterEntity }) => {
  const { riskBlacklist: { blacklistType } } = masterEntity;
  if (!blacklistType) return null;
  const [updateBlacklistType, { loading }] = useMutation(
    EDIT_RISK_BLACKLIST,
    {
      variables: { masterEntityId: masterEntity.id, blacklistType: true },
    },
  );
  return (
    <Chip
      label={blacklistType}
      color={blacklistType === 'BLACKLIST' ? 'error' : 'warning'}
      size="small"
      onDelete={updateBlacklistType}
      deleteIcon={
        loading ? <CircularProgress size={10} /> : (
          <Tooltip title={`Cambiar a ${blacklistType === 'BLACKLIST' ? 'Watchlist' : 'Blacklist'}`}>
            <CompareArrowsIcon />
          </Tooltip>
        )
      }
      disabled={loading}
    />
  );
};

RiskBlacklistTypeCell.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string,
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string,
      blacklistType: PropTypes.string,
    }),
  }).isRequired,
};

export default RiskBlacklistTypeCell;
