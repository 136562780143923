import React, { useState } from 'react';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { CREDIT_LINE_EVALUATION_REQUESTS } from '@fingo/lib/graphql';
import { COLUMNS, HEADER_TITLE } from '../constants/credit-line-evaluation-requests';
import CreditLineEvaluationRequestsColumns from '../constants/credit-line-evaluation-requests-columns';
import { CreditLineEvaluationActions } from './risk-evaluations';

const NoRows = () => <Typography variant="body2">No hay evaluaciones de líneas de crédito</Typography>;

const CreditLineEvaluationRequests = () => {
  const country = useGetCountryFromUrl();
  const columns = COLUMNS.concat(CreditLineEvaluationRequestsColumns);
  const [currency, setCurrency] = useState('');

  return (
    <FingoMainView
      id="credit-line-evaluation-requests-manager"
      query={CREDIT_LINE_EVALUATION_REQUESTS}
      queryCustomVariables={{
        creditlines_CreditLineEvaluationRequests_Status: 'Evaluating',
        countryId: country?.id,
        currency,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: CreditLineEvaluationActions,
      }}
      slotProps={{
        header: {
          viewTitle: HEADER_TITLE,
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          columns,
          checkboxSelection: false,
          noRowsMessage: NoRows,
          includeHeaders: [
            'masterEntity_Name',
            'requestDate',
            'suggestion',
            'limitAmount',
            'creditUsed',
            'resolutionStatus',
            'evaluations',
          ],
          initialOrderBy: 'masterEntity_Name',
          rowsPerPageOptions: [15, 25, 50, 100],
        },
        actions: {
          currency,
          setCurrency,
        },
      }}
    />
  );
};

export default CreditLineEvaluationRequests;
