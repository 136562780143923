import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import EditCreditLineDialog from './EditCreditLineDialog';

const EditCreditLineCell = ({ creditLine }) => {
  const [openEditCreditLineDialog, setOpenEditCreditLineDialog] = useState(false);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton
          size="small"
          align="center"
          onClick={() => setOpenEditCreditLineDialog(true)}
        >
          <Edit />
        </IconButton>
      </Box>
      {openEditCreditLineDialog && (
        <EditCreditLineDialog
          creditLine={creditLine}
          open={openEditCreditLineDialog}
          handleClose={() => setOpenEditCreditLineDialog(false)}
        />
      )}
    </>
  );
};

EditCreditLineCell.propTypes = {
  creditLine: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default EditCreditLineCell;
