import React from 'react';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import PaperHeader from '@fingo/lib/components/headers/PaperHeader';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import Typography from '@mui/material/Typography';
import { HEADER_TITLE, COLUMNS } from '../../constants/credit-line-manager';
import MasterEntityCreditLines from './MasterEntityCreditLines';
import CreditLineActions from './CreditLineActions';
import { CREDIT_LINE_MASTER_ENTITIES } from '../../graphql/credit-lines/queries';

const CreditLines = () => {
  const country = useGetCountryFromUrl();

  return (
    <FingoMainView
      id="credit-line-manager"
      query={CREDIT_LINE_MASTER_ENTITIES}
      queryCustomVariables={{
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: CreditLineActions,
      }}
      slotProps={{
        header: {
          viewTitle: HEADER_TITLE,
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          columns: COLUMNS,
          includeHeaders: ['name'],
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          checkboxSelection: false,
          isRowSelectable: () => true,
          disableSelectionOnClick: true,
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => (
            <MasterEntityCreditLines masterEntityId={row.graphqlId} />
          ),
          noRowsMessage: () => (
            <Typography variant="body2">No hay líneas de crédito</Typography>
          ),
          initialOrderBy: 'id',
          initialState: {
            expandedRowIds: (rows) => rows.map((row) => row.id),
          },
        },
      }}
    />
  );
};

export default CreditLines;
